<template>
    <div class="Profile-lessons" v-loading.fullscreen="loadingService">
        <div class="lessons-calendar">
            <div class="status">
                <div class="status__pass">
                    <div class="status__pass-circle"></div>
                    <div class="status__pass-title">
                        {{$t('profile.lessons.pass')}}
                    </div>
                </div>
                <div class="status__delay">
                    <div class="status__delay-circle"></div>
                    <div class="status__delay-title">
                        {{$t('profile.lessons.delay')}}
                    </div>
                </div>
            </div>
            <vue-cal
                :events="events"
                :time-from="7 * 60"
                :time-to="18 * 60"
                @view-change="viewChange($event)"
                @ready="ready($event)"
                click-to-navigate
                events-count-on-year-view="events-count-on-year-view"
                :events-on-month-view="'short'"
                :disable-views="['years', 'year', 'day']"
                active-view="month"
                locale="ru"
                :time-cell-height = "86"
                show-all-day-events
                :on-event-click="goToLesson"
                :specialHours="specialHours">
                <template v-slot:arrow-prev="">
                    <img src="/images/profile/arrow-prev.svg" alt="">
                </template>
                <template v-slot:arrow-next="">
                    <img src="/images/profile/arrow-next.svg" alt="">
                </template>

                <template v-slot:title="{ title, view }">
                    <span v-if="view.id === 'week'">{{ view.startDate.format('MMMM YYYY') }}</span>
                </template>

                <template v-slot:cell-content="{ cell, view, events }">
                    <div  class="vuecal__cell-date" :class="view.id">
                        <span class="clickable">
                            {{ cell.content }}
                        </span>
                    </div>
                    <span class="vuecal__cell-events-count" v-if="view.id === 'month' && events.length > 3">
                        Еще {{ events.length - 3 }} ...
                    </span>
                </template>
            </vue-cal>
            <div v-if="activeView === 'month'" class="text-danger mb-5">{{$t('profile.lessons.desc')}}</div>
            <div class="current-date">
                <div class="d-flex flex-row align-items-center">
                    <div class="current-date__day">
                        {{ currentDay }}
                    </div>
                    <div>
                        <div class="current-date__week">
                            {{ currentWeekDay }}
                        </div>
                        <div class="current-date__month">
                            {{ currentMonth }} {{ currentYear }}
                        </div>
                    </div>
                </div>
                <div class="current-date__today">
                    {{ $t("profile.schedule.today") }}
                </div>
            </div>

            <div class="work-type">
                <div class="work-type__col">
                    <div class="work-type__block">
                        <div class="work-type__circle work-type__circle--class"></div>
                        <div class="work-type__title">
                            {{$t('profile.lessons.class-work')}}
                        </div>
                    </div>
                    <div class="work-type__block">
                        <div class="work-type__circle work-type__circle--home"></div>
                        <div class="work-type__title">
                            {{$t('profile.lessons.home-work')}}
                        </div>
                    </div>
                </div>
                <div class="work-type__col">
                    <div class="work-type__block">
                        <div class="work-type__circle work-type__circle--control"></div>
                        <div class="work-type__title">
                            {{$t('profile.lessons.control-work')}}
                        </div>
                    </div>
                    <div class="work-type__block">
                        <div class="work-type__circle work-type__circle--test"></div>
                        <div class="work-type__title">
                            {{$t('profile.lessons.test')}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import moment from 'moment';
export default {
    name: '',
    components: {
        VueCal
    },
    data(){
        return {
            loadingTimeout: false,
            loadingService: false,
            activeView: 'month',
            showMoreLessons: false,
            dailyHours: { from: 9 * 60, to: 18 * 60, class: 'business-hours' },
            specialHours: {},
            events: [],
            eventsArr: [],
            attrs: [
                {
                    key: 'today',
                    highlight: true,
                    dates: new Date(),
                },
            ],
            currentDay: moment().format("D"),
            currentMonth: moment().locale('ru').format("MMMM"),
            currentYear: moment().format("YYYY"),
            currentWeekDay: moment().locale('ru').format('dddd')
        }
    },
    beforeCreate() {
        if (this.$i18n.locale === 'kz') {
            require('../../locales/vuecal-kz.js');
        } else {
            require('vue-cal/dist/i18n/ru.js');
        }
    },
    methods: {
        viewChange(e) {
            this.activeView = e.view
            this.getData(e)
        },
        ready(e) {
            this.getData(e)
        },
        goToLesson(event) {
            if (this.activeView === 'week') {
                this.$router.push({name: 'lesson-detail', params: {id: event.id}})
            }
        },
        getData(e) {
            this.loadingService = true;
            this.loadingTimeout = setTimeout(() => {
                this.loadingService = false;
            }, 5000);
            this.loading = true;
            if (e.view === 'month') {
                this.events = [];
                let month = e.startDate.getMonth();
                this.$http.get(`${window.API_ROOT}/api/student/lessons/month?month=${month + 1}`)
                    .then((res) => {
                        this.loading = false
                        this.events = res.body.attendances
                        this.loadingService = false
                        window.clearTimeout(this.loadingTimeout)
                    }).catch(() => {
                        this.loading = false
                        this.loadingService = false
                        window.clearTimeout(this.loadingTimeout)
                    })
            } else if (e.view === 'week') {
                let date = moment(e.startDate).format('YYYY-MM-DD');
                this.$http.get(`${window.API_ROOT}/api/student/lessons/week?week=${date}`)
                    .then((res) => {
                        this.loading = false
                        this.events = res.body.data
                        this.loadingService = false
                        window.clearTimeout(this.loadingTimeout)
                    }).catch(() => {
                        this.loading = false
                        this.loadingService = false
                        window.clearTimeout(this.loadingTimeout)
                    })
            }
        },
    }
}
</script>
<style>
.lessons-calendar {
    position: relative;
    margin: 0 auto;
}
.lessons-calendar .vuecal--month-view, .vuecal--week-view {
    box-shadow: none;
    font-family: "Roboto Condensed", sans-serif;
    padding-top: 48px;
    padding-bottom: 50px;
}
.lessons-calendar .vuecal__menu {
    background-color: transparent;
    padding-bottom: 30px;
}
.lessons-calendar .vuecal__header .vuecal__view-btn {
    color: #473F95;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    background-color: transparent;
    border: 1px solid #473F95;
    box-sizing: border-box;
    width: 218px;
    height: 36px;
}
.lessons-calendar .vuecal__header .vuecal__view-btn:first-child{
    border-radius: 5px 0 0 5px;
}
.lessons-calendar .vuecal__header .vuecal__view-btn:nth-child(2){
    border-radius: 0 5px 5px 0;
}
.lessons-calendar .vuecal__header .vuecal__view-btn:nth-child(3){
    display: none;
}
.lessons-calendar .vuecal__view-btn--active {
    background: #473F95!important;
    color: #fff!important;
}
.lessons-calendar .vuecal__title-bar {
    background-color: transparent;
    color: #473F95!important;
    width: 350px;
    margin: 0 auto 60px auto;
}
.lessons-calendar .vuecal__title,
.lessons-calendar .vuecal__title button span {
    color: #343F68;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    padding: 15px 0;
}
.lessons-calendar .vuecal__weekdays-headings {
    padding-right: 0!important;
    margin-bottom: 30px;
}
.lessons-calendar .vuecal--week-view .vuecal__weekdays-headings {
    border-bottom: none;
}
.lessons-calendar .weekday-label {
    background-color: #E3E2F2;
    border: 1px solid #E9E9E9;
}
.lessons-calendar .vuecal--month-view .vuecal__heading .small {
    display: block;
    text-transform: uppercase;
    color: #343F68;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    padding: 13px 0;
}
.lessons-calendar .vuecal__heading .xsmall,
.lessons-calendar .vuecal__heading .full {
    display: none;
}
.lessons-calendar .vuecal__cell {
    background-color: #fff;
}
.lessons-calendar .vuecal--month-view .vuecal__cell--today .clickable {
    color: #D23168;
    border-bottom: 2px solid #D23168;
}
.lessons-calendar .vuecal--month-view .vuecal__cell-date {
    height: 94px;
    color: #343F68;
    font-weight: bold;
    font-size: 16px;
    line-height: 94px;
    text-align: left;
    padding-left: 6px;
}
.lessons-calendar .vuecal--month-view .vuecal__cell-date .clickable {
    /* position: absolute; */
    z-index: 9;
    width: 100%;
    margin-left: -7px;
    padding-left: 7px;
}
.lessons-calendar .vuecal--month-view .vuecal__cell--out-of-scope .vuecal__cell-date {
    color: #C0C3CC;
}
.lessons-calendar .vuecal--month-view .vuecal__cell--out-of-scope .vuecal__cell-content {
    background: #FFFFFF;
}
.lessons-calendar .vuecal--month-view .vuecal__cell-events {
    position: absolute;
    width: fit-content;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.lessons-calendar .vuecal--month-view .vuecal__cell-events .vuecal__event.delay,
.lessons-calendar .vuecal--month-view .vuecal__cell-events .vuecal__event.pass{
    display: block !important;
    position: absolute;
    z-index: 7;
}
.lessons-calendar .vuecal--month-view .vuecal__event-time {
    display: none;
}
.lessons-calendar .vuecal--month-view .vuecal__cell-content {
    position: relative;
    background-color: #E3E2F2;
    border: 1px solid #E9E9E9;
    overflow: hidden;
}
.lessons-calendar .vuecal--month-view .vuecal__cell-events .vuecal__event {
    display: none;
}
.lessons-calendar .vuecal--month-view .vuecal__cell-events .vuecal__event:nth-child(1) {
    display: block;
    position: absolute;
    z-index: 8;
    top: 0;
    bottom: 0;
    padding: 8px 0;
}
.lessons-calendar .vuecal--month-view .vuecal__event {
    background-color: transparent;
    cursor: pointer;
}
.lessons-calendar .vuecal--month-view .vuecal__event-title {
    color: #343F68;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 0;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.lessons-calendar .vuecal--month-view .no-rating{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: #e3e2f2;
}
.lessons-calendar .vuecal--month-view .no-rating .vuecal__event-title{
    position: absolute;
    color: #473f95;
    font-weight: bold;
    top: 0;
    left: 20px;
    right: 10px;
    text-align: center;
    white-space: break-spaces;
    line-height: 18px;
}
.lessons-calendar .vuecal--month-view .vuecal__event--focus, .vuecal__event:focus {
    box-shadow: none;
    cursor: pointer;
    outline: none;
}
.lessons-calendar .vuecal__cell--day6 .vuecal__cell-content,
.lessons-calendar .vuecal__cell--day7 .vuecal__cell-content {
    background-color: #fff;
}
.lessons-calendar .vuecal--month-view .vuecal__cell-events {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.lessons-calendar .vuecal--month-view .vuecal__event {
    display: flex;
    justify-content: center;
}
.lessons-calendar .vuecal--month-view .vuecal__cell-events-count {
    display: none;
    color: #473F95;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    background-color: transparent;
    left: auto;
    right: -18px;
    top: 70px;
    z-index: 10;
}
.lessons-calendar .vuecal--week-view .vuecal__cell-content {
    position: relative;
    background-color: #FAFAFA;
    border: 1px solid #E9E9E9;
}
.lessons-calendar .vuecal--week-view .vuecal__heading {
    height: 64px;
}
.lessons-calendar .vuecal--week-view .today .weekday-label {
    background: #473F95;
    color: #fff;
}
.lessons-calendar .vuecal--week-view .today .weekday-label .small,
.lessons-calendar .vuecal--week-view .today .weekday-label span:last-child {
    color: #fff;
}
.lessons-calendar .vuecal--week-view .vuecal__heading .small {
    display: block;
    text-transform: uppercase;
    color: #343F68;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    padding-left: 6px;
}
.lessons-calendar .vuecal--week-view .weekday-label span:last-child {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: #343F68;
}
.lessons-calendar .vuecal--week-view .vuecal__heading .weekday-label {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.lessons-calendar .vuecal__time-cell-label {
    color: #343F68;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding-right: 12px;
}
.lessons-calendar .vuecal--week-view .vuecal__event-time {
    display: none;
}
.lessons-calendar .vuecal--week-view .vuecal__event-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
}
.lessons-calendar .vuecal--week-view  .vuecal__event-content {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
}
.lessons-calendar .vuecal--week-view  .vuecal__cell:last-child {
    background-color: #FAFAFA;
}
.lessons-calendar .vuecal--week-view .vuecal__cell--today .vuecal__cell-content .vuecal__event {
    background: #E3E2F2;
}
.lessons-calendar .vuecal--week-view .vuecal__event-content {
    color: #473F95;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.lessons-calendar .vuecal--month-view .vuecal__event-title span {
    color: #fff;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    background-color: #473F95;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px auto;
}
.lessons-calendar .vuecal--week-view .no-rating .vuecal__event-title{
    font-size: 14px;
    line-height: 26px;
    display: flex;
    justify-content: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    color: #51499b;
}
.lessons-calendar .vuecal--week-view .vuecal__event .vuecal__event-title span {
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    background-color: #473F95;
    border-radius: 50%;
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
}
.lessons-calendar .vuecal--week-view .vuecal__event .vuecal__event-title span.homework{
    background-color: #55953F;
}
.lessons-calendar .vuecal--week-view .vuecal__event .vuecal__event-title span.controlwork{
    background-color: #D23168;
}
.lessons-calendar .vuecal--week-view .vuecal__event .vuecal__event-title span.testresult{
    background-color: #DE9A06;
}
.lessons-calendar .vuecal--month-view .vuecal__event-title span.homework{
    background-color: #55953F;
}
.lessons-calendar .vuecal--month-view .vuecal__event-title span.controlwork{
    background-color: #D23168;
}
.lessons-calendar .vuecal--month-view .vuecal__event-title span.testresult{
    background-color: #DE9A06;
}
.lessons-calendar .vuecal--week-view .vuecal__event {
    border-top: 1px solid #E9E9E9;
    border-bottom: 1px solid #E9E9E9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px 6px;
    background: #E3E2F2;
}
.lessons-calendar .vuecal--week-view .vuecal__cell--today .vuecal__cell-content .vuecal__event.white{
    background-color: #fff;
}
.lessons-calendar .vuecal--week-view .vuecal__event.white{
    background-color: #fff;
}
.lessons-calendar .vuecal--week-view .vuecal__cell--today .vuecal__cell-content .vuecal__event.primary{
    background-color: #473f95;
}
.lessons-calendar .vuecal--week-view .vuecal__event.primary .vuecal__event-title span{
    background-color: #fff;
    color: #473F95;
}
.lessons-calendar .vuecal--week-view .vuecal__event.primary .vuecal__event-content{
    color: #fff;
}
.lessons-calendar .vuecal--week-view .primary.no-rating .vuecal__event-title{
    color: #343f68;
}
.lessons-calendar .vuecal__now-line {
    display: none
}
.status {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    right: 0;
    top: 135px;
}
.status__pass,
.status__delay {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 14px;
    line-height: 16px;
    color: #303030;
    align-items: center;
}
.status__pass-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #FBEAEA;
    border: 1px solid #FFD5E3;
    margin-right: 12px;
}
.status__pass {
    margin-right: 24px;
}
.status__delay-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #FFF7CE;
    border: 1px solid #FFEABC;
    margin-right: 12px;
}
.work-type {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.work-type .work-type__col:first-child {
    margin-right: 24px;
}
.work-type__col .work-type__block:first-child   {
    margin-bottom: 24px;
}
.work-type__block {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.work-type__title {
    color: #303030;
    font-size: 14px;
    line-height: 16px;
}
.work-type__circle {
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 12px;
}
.work-type__circle--class {
    background: #473F95;
    border: 1px solid #473F95;
}
.work-type__circle--home {
    background: #55953F;
    border: 1px solid #55953F;
}
.work-type__circle--control {
    background: #D23168;
    border: 1px solid #D23168;
}
.work-type__circle--test {
    background: #DE9A06;
    border: 1px solid #DE9A06;
}
.pass {
    background-color: #FBEAEA!important;
    position: absolute;
    width: 100%;
    height: 100%;
}
.delay {
    background-color: #FFF7CE!important;
    position: absolute;
    width: 100%;
    height: 100%;
}
.test {
    background-color: #473f95db!important;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("/images/profile/calendar-event.svg");
    background-position: 30px;
    background-repeat: no-repeat;
    background-size: 17px;
}
.vuecal__all-day {
    display: none;
}
.lesson-info-btn {
    border: none;
    background-color: transparent;
    padding: 0;
}
.lessons-calendar .vuecal--week-view  .test-title {
    border-top: 1px solid #E9E9E9;
    border-bottom: 1px solid #E9E9E9;
    padding: 8px;
    align-items: center;
    display: flex;
    background-color: #fff;
}
.lessons-calendar .vuecal--week-view .test-title--light-purple {
    background-color: #E3E2F2;
}
.lessons-calendar .vuecal--week-view .test-title--purple {
    background: #473F95;
}
.lessons-calendar .vuecal--week-view .test-title--purple .vuecal__event-content {
    color: #fff;
}
.lessons-calendar .vuecal--week-view .test-title .vuecal__event-content {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.current-date {
    display: none;
}
.detailed-schedule {
    max-width: 345px;
    display: none;
}
.detailed-schedule__header {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #A39FCA;
    padding-bottom: 24px;
}
.detailed-schedule__header-time {
    width: 60px;
}
.detailed-schedule__filter {
    border: none;
    background: #fff;
}
.detailed-schedule__row {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
}
.detailed-schedule__time {
    width: 60px;
    padding-right: 16px;
    border-right: 2px solid #FAF9F9;
}
.detailed-schedule__lesson-block {
    background: #E3E2F2;
    border-radius: 16px;
    padding: 12px 16px 16px 16px;
    margin-left: 16px;
    position: relative;
    width: calc(100% - 60px);
    min-height: 134px;
}
.detailed-schedule__header-lesson-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 60px);
}
.detailed-schedule__time  div:first-child {
    color: #E3E2F2;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 7px;
}
.detailed-schedule__time  div:last-child {
    color: #A39FCA;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}
.detailed-schedule__lesson {
    color: #473F95;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 13px;
}
.detailed-schedule__theme {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #473F95;
    margin-bottom: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.detailed-schedule__connect {
    margin: 0 auto;
    display: block;
    background: #E3E2F2;
    border: 1px solid #A39FCA;
    box-sizing: border-box;
    border-radius: 5px;
    color: #A39FCA;
    padding: 7px 32px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
}
.detailed-schedule__btn {
    padding: 0;
    border: none;
    background-color: transparent;
    position: absolute;
    right: 16px;
}
.detailed-schedule__row .detailed-schedule__dots-white,
.detailed-schedule__row .detailed-schedule__dots-grey {
    display: none;
}
.detailed-schedule__row .detailed-schedule__dots-purple {
    display: block;
}
.detailed-schedule__row--grey .detailed-schedule__time div:first-child {
    color: #F5F5F5;
}
.detailed-schedule__row--grey .detailed-schedule__time div:last-child {
    color: #A39FCA;
}
.detailed-schedule__row--grey .detailed-schedule__lesson-block {
    background: #fff;
    border: 1px solid #E9E9E9;
}
.detailed-schedule__row--grey .detailed-schedule__lesson,
.detailed-schedule__row--grey .detailed-schedule__theme {
    color: #473F95;
    padding-bottom: 21px;
    padding-top: 7px;
}

.detailed-schedule__row.detailed-schedule__row--grey .detailed-schedule__dots-white,
.detailed-schedule__row.detailed-schedule__row--grey .detailed-schedule__dots-purple {
    display: none;
}
.detailed-schedule__row.detailed-schedule__row--grey .detailed-schedule__dots-grey {
    display: block;
}

.detailed-schedule__row--purple .detailed-schedule__time div:first-child {
    color: #473F95;
}
.detailed-schedule__row--purple .detailed-schedule__time div:last-child {
    color: #A39FCA;
}
.detailed-schedule__row--purple .detailed-schedule__lesson-block {
    background: #473F95;
}
.detailed-schedule__row--purple .detailed-schedule__lesson,
.detailed-schedule__row--purple .detailed-schedule__theme {
    color: #FFFFFF;
}
.detailed-schedule__row--purple .detailed-schedule__connect {
    background: #D23168;
    border: 1px solid #D23168;
    color: #FFFFFF;
}
.detailed-schedule__row.detailed-schedule__row--purple .detailed-schedule__dots-purple,
.detailed-schedule__row.detailed-schedule__row--purple .detailed-schedule__dots-grey {
    display: none;
}
.detailed-schedule__row.detailed-schedule__row--purple .detailed-schedule__dots-white {
    display: block;
}
.detailed-schedule__more-btn {
    color: #473F95;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration: underline;
    border: none;
    background-color: transparent;
}
.detailed-schedule__result-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.detailed-schedule__result {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    width: 51px;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #473F95;
    border-radius: 50%;
    margin-right: 5px;
}
.detailed-schedule__result-row .detailed-schedule__result:last-child {
    margin-right: 0;
}
.detailed-schedule__result--yellow {
    background: #DE9A06;
}
.detailed-schedule__result--green {
    background: #55953F;
}
.detailed-schedule__result--single {
    width: 68px;
    height: 68px;
    font-size: 18px;
    line-height: 21px;
}
.detailed-schedule__info-btn {
    padding: 0;
    border: none;
    background-color: transparent;
}
.info-popper {
    padding: 12px;
    font-family: 'Roboto', sans-serif;
}
.info-popper__title {
    color: #473F95;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}
.info-popper__status {
    color: #473F95;
    padding: 13px;
    font-size: 16px;
    line-height: 19px;
    background: #EEEAFB;
    border-radius: 10px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.info-popper__text {
    padding-bottom: 15px;
    color: #473F95;
    font-size: 14px;
    line-height: 16px;
    word-wrap: break-word;
}
.info-popper__date {
    color: #473F95;
    font-size: 14px;
    line-height: 16px;
    padding-top: 10px;
    border-top: 1px solid #473F95;
}
.info-popper--yellow .info-popper__date {
    color: #DE9A06;
    border-top: 1px solid #DE9A06;
}
.info-popper--yellow .info-popper__status {
    color: #DE9A06;
    background: #FFF7CE;
}
.info-popper--green .info-popper__date{
    color: #55953F;
    border-top: 1px solid #55953F;
}
.info-popper--green .info-popper__status {
    color: #55953F;
    background: #F4FBEA;
}
.info-popper--pink .info-popper__date{
    color: #D23168;
    border-top: 1px solid #D23168;
}
.info-popper--pink .info-popper__status {
    color: #D23168;
    background: #FBEAEA;
}
.el-button:focus, .el-button:hover {
    border-color: transparent;
    background-color: transparent;
}
@media screen and (max-width: 1300px) {
    .lessons-calendar .vuecal {
        margin: 0 auto;
    }
}
@media screen and (max-width: 1200px) {
    .lessons-calendar .vuecal--month-view .vuecal__cell-events-count {
        font-size: 9px;
        line-height: 13px;
    }
}
@media screen and (max-width: 991px) {
    .lessons-calendar .vuecal--month-view, .vuecal--week-view {
        padding-top: 0;
    }
    .lessons-calendar .vuecal__view-btn {
        background: #04C2F4!important;
        color: #fff!important;
    }
    .lessons-calendar .vuecal__view-btn--active {
        background: #fff!important;
        color: #04C2F4!important;
    }
    .lessons-calendar .vuecal__header .vuecal__view-btn {
        border: 1px solid #fff;
        height: 42px;
        width: 100%;
        max-width: 218px;
    }
    .lessons-calendar .vuecal__flex.vuecal__menu {
        display: none;
    }
    .lessons-calendar .vuecal--month-view .vuecal__cell-events-count {
        display: none;
    }
    .lessons-calendar .vuecal__arrow {
        z-index: auto;
    }
    .lessons-calendar .vuecal__title-bar {
        padding: 0;
    }
    .lessons-calendar {
        padding: 0 16px;
    }
    .lessons-calendar .vuecal--month-view .vuecal__heading .small {
        color: #473F95;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-transform: capitalize;
        padding: 0;
    }
    .lessons-calendar .vuecal__title {
        font-size: 18px;
        font-weight: bold;
    }
    .lessons-calendar .vuecal--month-view .vuecal__cell--today .vuecal__event-title {
        font-size: 0;
        border: none;
        background: #fff;
        border-radius: 50%;
        width: 15px;
    }
    .lessons-calendar .vuecal--month-view .vuecal__cell-events .vuecal__event:nth-child(2) {
        display: none;
    }
    .lessons-calendar .vuecal--month-view .vuecal__cell-events .vuecal__event:first-child {
        display: block;
    }
    .lessons-calendar .vuecal--month-view .vuecal__cell-events {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-top: 0;
    }
    .lessons-calendar .vuecal--month-view .circle-pink .vuecal__event-title,
    .lessons-calendar .vuecal--month-view .circle-purple .vuecal__event-title,
    .lessons-calendar .vuecal--month-view .circle-green .vuecal__event-title {
        display: none;
    }
    .lessons-calendar .vuecal__cells.month-view .vuecal__cell {
        height: 44px;
        z-index: 0;
    }
    .lessons-calendar .vuecal--month-view .vuecal__cell-date {
        text-align: center;
        padding-left: 0;
        height: 44px;
        line-height: 44px;
    }
    .lessons-calendar .weekday-label {
        background-color: transparent;
        border: none;
    }
    .lessons-calendar .vuecal__weekdays-headings {
        border: none;
        margin-bottom: 11px;
    }
    .test {
        background-image: none;
    }
    .status {
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: fit-content;
        top: 75px;
    }
    .work-type__title {
        font-size: 12px;
        line-height: 14px;
    }
    .work-type__circle {
        width: 19px;
        height: 19px;
    }
    .current-date {
        color: #473F95;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 32px;
        max-width: 345px;
    }
    .current-date__day {
        font-weight: 500;
        font-size: 44px;
        line-height: 52px;
        margin-right: 35px;
    }
    .current-date__week,
    .current-date__month {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-transform: capitalize;
    }
    .current-date__week {
        padding-bottom: 6px;
    }
    .current-date__today {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        padding: 10px 19px;
        border-radius: 8px;
        height: fit-content;
        background: #E3E2F2;
    }
    .detailed-schedule {
        display: block;
    }
    .work-type {
        margin-bottom: 32px;
    }
    .lessons-calendar {
        padding: 0;
    }
    .lessons-calendar .vuecal--month-view .vuecal__cell--today .vuecal__cell-content {
        background-color: #D23168;
    }
    .lessons-calendar .vuecal--month-view .vuecal__cell--today .vuecal__cell-content .clickable {
        border-bottom:  none;
        color: #fff;
    }
    .lessons-calendar .vuecal--month-view .vuecal__cell-content {
        transform-style: preserve-3d;
    }
    .lessons-calendar .vuecal--month-view .vuecal__cell-date {
        transform: translate3d(0, 0, 1px);
    }
}
@media (max-width: 575px) {
    .current-date,
    .detailed-schedule  {
        max-width: 100%;
    }
    .calendar .vuecal__title-bar {
        width: 100%;
        padding: 0;
    }
}

</style>